import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Obfuscate from 'react-obfuscate'
import { FiExternalLink } from "react-icons/fi";
import { FacebookProvider, Page } from 'react-facebook';


const Contact = () => (
  <Layout>
    <SEO title="Contact" />

    <HeaderImage />

    <div className="lg:px-4 mt-16">
      <div className="lg:flex lg:-mx-4">
        <div className="w-full lg:w-1/2 lg:pr-4 max-w-xl">
          <h2 className="text-sand-400 text-4xl lg:text-5xl leading-none uppercase mb-2 font-semibold">Contact</h2> 
          <p className="text-justify">
            Heeft u een vraag, neemt u dan vooral contact met ons op. 
          </p> 
         
          <p className="mb-8">
            <span className="text-sm">E-mail:</span>{` `}<Obfuscate email="info.classicurls@gmail.com" className="underline"/> <br />
          </p>

          <p className="underline leading-relaxed">
            <a href="https://nienkenasserian.com/" target="_blank" rel="noopener noreferrer">Nienke Nasserian <FiExternalLink className="inline-block"/></a><br />
            <a href="http://www.mattanjalennings.com/" target="_blank" rel="noopener noreferrer">Mattanja Lennings <FiExternalLink className="inline-block"/></a><br />
            <a href="https://www.linkedin.com/in/lucie-van-ree-048390141/" target="_blank" rel="noopener noreferrer">Lucie van Ree <FiExternalLink className="inline-block"/></a>
          </p>

        </div>

        <div className="lg:flex-1 lg:pl-4 mt-12 lg:mt-0 lg:text-right">
          <h2 className="text-sand-400 text-4xl lg:text-5xl leading-none uppercase mb-2 font-semibold">Socials</h2> 
          
          <p>
            Like Classicurls op <a href="https://nl-nl.facebook.com/classicurls/" target="_blank" rel="noopener noreferrer" className="underline">Facebook <FiExternalLink className="inline-block"/></a><br />
            Volg @_classicurls_ op <a href="https://www.instagram.com/_classicurls_/" target="_blank" rel="noopener noreferrer" className="underline">Instagram <FiExternalLink className="inline-block"/></a>
          </p>

          <div className="mt-8">
            <FacebookProvider appId="842046292812116" wait>
              <Page href="https://www.facebook.com/classicurls/" tabs="timeline" />
            </FacebookProvider>   
          </div>
            
        </div>

      </div>
    </div>
  </Layout>
)

const HeaderImage = () => (
  <StaticQuery
    query={graphql`
      query {
        imageHome: file(relativePath: { eq: "media/Classicurls-01.jpg" }) {
          childImageSharp {
            fluid(
              maxWidth: 2000,
              duotone: {
                highlight: "#e7d7b8",
                shadow: "#212126", 
                opacity:30
              }
            ){
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Img fluid={data.imageHome.childImageSharp.fluid} className="max-h-half-screen"/>}
  />
)

export default Contact
